import React, { useEffect } from "react"
import { navigate } from "gatsby"

const Services: React.FC = () => {
  useEffect(() => {
    navigate("/dienstleistungen/augenvorsorge/")
  })

  return null
}

export default Services
